<template>
  <div>
    <section class="text-gray-700">
      <div class="container px-5 py-24 mx-auto">
        <div class="text-center mb-20">
          <h1
            class="
              sm:text-3xl
              text-2xl
              font-medium
              text-center
              title-font
              text-gray-900
              mb-4
            "
          >
            Frequently Asked Question
          </h1>
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            The most common questions about how our business works and what can
            do for you.
          </p>
        </div>
        <div class="align-center mx-2">
          <div class="w-full px-4 py-2">
            <div v-for="faq in faqs" :key="faq">
              <details class="mb-4">
                <summary class="font-semibold rounded-md py-2 px-4">
                  {{ faq.title }}
                </summary>

                <span>
                  {{ faq.answer }}
                </span>
              </details>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script lang="js">
export default {
  name: "faq",
  data() {
    return {
      faqs: [
        {
          "title": "How to place an Order?",
          "answer": "To place an Order you need to contact us, then we will assign to right person for processing."
        },
      ]
    }
  }
}
</script>
<style scoped>
summary {
  cursor: pointer;
}
</style>
